import { Action, RuntimeFilterOp } from "@thoughtspot/visual-embed-sdk";
import "./Board.css"
import { LiveboardEmbed } from "@thoughtspot/visual-embed-sdk/react";
import BoardDefault from "./BoardDefault";
import BoardPdf from "./BoardPdf";

export enum BoardTypes {
    Liveboard = "liveboard",
    PDF = "PDF",
    DEFAULT = "DEFAULT",
    LiveboardObject = "liveboardEmbedded",
    Mobile = "mobile"
}

export type BoardProps = {
    boardId: string,
    name: string,
    type: BoardTypes,
    selectedStoreNo?: string,
    accessLevel: string,
    sendFilter?: boolean, // TODO: Kommer ikke fra MenuItem
    sendParameter?: boolean // brukes til default store plukkes fra meny
    hideHeader?: boolean
}

const availableActions = [
    // Top level (entire liveboard)
    Action.DownloadAsPdf,
    Action.Present,
    // Visualisations
    Action.DrillDown,
    Action.CrossFilter,
    Action.ShareViz,
    Action.Download, // Allows opening of the print menu
    Action.DownloadAsXlsx, // Allows printing as Excel file from print menu

    // On selected data
    Action.CopyToClipboard
]


const Board = ({ boardId, type, selectedStoreNo, sendFilter, sendParameter, hideHeader }: BoardProps) => {

    switch (type) {
        case BoardTypes.DEFAULT:
            console.debug(`Board.tsx: ${BoardTypes.DEFAULT}`)
            return (
                <BoardDefault/>
            )

        case BoardTypes.PDF:
            console.debug(`Board.tsx: ${BoardTypes.PDF} with filename ${boardId}`)
            return (
                <BoardPdf fileName={boardId}/>
            )

        case BoardTypes.Liveboard:
            console.debug(`Board.tsx: ${BoardTypes.Liveboard} with id ${boardId}`)
            console.log(`sendFilter ${sendFilter}`)
            console.log(`sendParameter ${sendParameter}`)
            console.log(`selectedStoreNo: ${selectedStoreNo}`)
 
            return (
                <LiveboardEmbed className="board"
                                key={`${boardId}${selectedStoreNo}`}
                                runtimeFilters={sendFilter
                                    ? [{
                                            columnName: "region default store no",
                                            operator: RuntimeFilterOp.EQ,
                                            values: [selectedStoreNo || ""]
                                        }]
                                    : undefined
                                }
                                runtimeParameters={sendParameter
                                    ? [{
                                            name: "valgt butikk",
                                            value: selectedStoreNo + ""
                                        }] 
                                    : undefined
                                }
                                hideLiveboardHeader={hideHeader || false}
                                visibleActions={availableActions}
                                liveboardId={boardId}/>
            )

        case BoardTypes.Mobile:
            console.debug(`Board.tsx: ${BoardTypes.Mobile} with board ID ${boardId}`)
            return (
                <div className={"mobileContainer"}>
                    <LiveboardEmbed
                        className={"board"}
                        key={`${boardId}${selectedStoreNo}`}
                        hideLiveboardHeader={true}
                        liveboardId={boardId}
                        visibleActions={availableActions}
                        runtimeParameters={
                            [{
                                name: "valgt butikk",
                                value: selectedStoreNo + ""
                            }]
                        }
                        doNotTrackPreRenderSize={false}
                        enable2ColumnLayout={true}
                        liveboardV2={true}
                    />
                </div>
            )

        // Was used when thoughtspot SDK was slow. The SDK seams better now, so it is being used instead. However, this is kept so that its
        // quicker to change back in case someone finds out thats a good idea in terms of performance.
        case BoardTypes.LiveboardObject:
            console.debug(`Board.tsx: ${BoardTypes.LiveboardObject} with id ${boardId}`)
            return (
                <embed key={boardId}
                       src={`https://tribunen.europris.no/?col1=Store%20No&op1=EQ&val1=${selectedStoreNo}&embedApp=true#/pinboard/${boardId}/`}
                       width={"100%"} height={"100%"}>
                </embed>
            )

        default:
            console.debug(`Board.tsx: ${type} with id ${boardId}`)
            console.warn(`BoardType not supported, got ${type}`)
            return (
                <>
                    <p>Dette er ikke lagt inn /o\</p>
                </>
            )
    }
}

export default Board
